import { Field, Form, Formik } from "formik";
import { Link } from "gatsby";
import React, { useContext, useRef } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import CartContext, { SHIPPING_PRICES } from "../../context/cart-context";
import { allDeliverableZipCodes, zipCodes } from "../../data/zip-codes";

export const shippingPrice = (shippingZip) => {
  if (zipCodes[0].zipCodes.indexOf(+shippingZip) > -1)
    return `$${SHIPPING_PRICES[process.env.COMPANY_NAME_SHORT][0]} for ${
      zipCodes[0].label
    } Delivery Area`;
  if (zipCodes[1].zipCodes.indexOf(+shippingZip) > -1)
    return `$${SHIPPING_PRICES[process.env.COMPANY_NAME_SHORT][1]} for ${
      zipCodes[1].label
    } Delivery Area`;
  if (zipCodes[2].zipCodes.indexOf(+shippingZip) > -1)
    return `$${SHIPPING_PRICES[process.env.COMPANY_NAME_SHORT][2]} for ${
      zipCodes[2].label
    } Delivery Area`;

  return "Please enter your zip code to see shipping pricing";
};

function ServiceAreasPageTemplate(props) {
  const formikRef = useRef(null);
  const cartContext = useContext(CartContext);

  return (
    <Layout>
      <Seo
        title={props.currentPage.seo.title}
        description={props.currentPage.seo.metaDesc}
      />
      <Helmet>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Service",
          "serviceType": "Window Well Covers",
          "areaServed": [
            {
              "@type": "City",
              "name": "Salt Lake City",
              "sameAs": "https://en.wikipedia.org/wiki/Salt_Lake_City"
            },
            {
              "@type": "City",
              "name": "West Valley",
              "sameAs": "https://en.wikipedia.org/wiki/West_Valley,_Utah"
            },
            {
              "@type": "City",
              "name": "Provo",
              "sameAs": "https://en.wikipedia.org/wiki/Provo,_Utah"
            },
            {
              "@type": "City",
              "name": "West Jordan",
              "sameAs": "https://en.wikipedia.org/wiki/West_Jordan,_Utah"
            },
            {
              "@type": "City",
              "name": "Sandy",
              "sameAs": "https://en.wikipedia.org/wiki/Sandy,_Utah"
            },
            {
              "@type": "City",
              "name": "Orem",
              "sameAs": "https://en.wikipedia.org/wiki/Orem,_Utah"
            },
            {
              "@type": "City",
              "name": "Ogden",
              "sameAs": "https://en.wikipedia.org/wiki/Ogden,_Utah"
            },
            {
              "@type": "City",
              "name": "Layton",
              "sameAs": "https://en.wikipedia.org/wiki/Layton,_Utah"
            },
            {
              "@type": "City",
              "name": "South Jordan",
              "sameAs": "https://en.wikipedia.org/wiki/South_Jordan,_Utah"
            },
            {
              "@type": "City",
              "name": "Taylorsville",
              "sameAs": "https://en.wikipedia.org/wiki/Taylorsville,_Utah"
            },
            {
              "@type": "City",
              "name": "Millcreek",
              "sameAs": "https://en.wikipedia.org/wiki/Millcreek,_Utah"
            },
            {
              "@type": "City",
              "name": "Lehi",
              "sameAs": "https://en.wikipedia.org/wiki/Lehi,_Utah"
            },
            {
              "@type": "City",
              "name": "Murray",
              "sameAs": "https://en.wikipedia.org/wiki/Murray,_Utah"
            },
            {
              "@type": "City",
              "name": "Draper",
              "sameAs": "https://en.wikipedia.org/wiki/Draper,_Utah"
            },
            {
              "@type": "City",
              "name": "Bountiful",
              "sameAs": "https://en.wikipedia.org/wiki/Bountiful,_Utah"
            },
            {
              "@type": "City",
              "name": "Riverton",
              "sameAs": "https://en.wikipedia.org/wiki/Riverton,_Utah"
            },
            {
              "@type": "City",
              "name": "Spanish Fork",
              "sameAs": "https://en.wikipedia.org/wiki/Spanish_Fork,_Utah"
            },
            {
              "@type": "City",
              "name": "Roy",
              "sameAs": "https://en.wikipedia.org/wiki/Roy,_Utah"
            },
            {
              "@type": "City",
              "name": "Pleasant Grove",
              "sameAs": "https://en.wikipedia.org/wiki/Pleasant_Grove,_Utah"
            },
            {
              "@type": "City",
              "name": "Kearns",
              "sameAs": "https://en.wikipedia.org/wiki/Kearns,_Utah"
            },
            {
              "@type": "City",
              "name": "Cottonwood Heights",
              "sameAs": "https://en.wikipedia.org/wiki/Cottonwood_Heights,_Utah"
            },
            {
              "@type": "City",
              "name": "Tooele",
              "sameAs": "https://en.wikipedia.org/wiki/Tooele,_Utah"
            },
            {
              "@type": "City",
              "name": "Springville",
              "sameAs": "https://en.wikipedia.org/wiki/Springville,_Utah"
            },
            {
              "@type": "City",
              "name": "Midvale",
              "sameAs": "https://en.wikipedia.org/wiki/Midvale,_Utah"
            },
            {
              "@type": "City",
              "name": "Herriman",
              "sameAs": "https://en.wikipedia.org/wiki/Herriman,_Utah"
            },
            {
              "@type": "City",
              "name": "Holladay",
              "sameAs": "https://en.wikipedia.org/wiki/Holladay,_Utah"
            },
            {
              "@type": "City",
              "name": "Clearfield",
              "sameAs": "https://en.wikipedia.org/wiki/Clearfield,_Utah"
            },
            {
              "@type": "City",
              "name": "Kaysville",
              "sameAs": "https://en.wikipedia.org/wiki/Kaysville,_Utah"
            },
            {
              "@type": "City",
              "name": "American Fork",
              "sameAs": "https://en.wikipedia.org/wiki/American_Fork,_Utah"
            },
            {
              "@type": "City",
              "name": "Eagle Mountain",
              "sameAs": "https://en.wikipedia.org/wiki/Eagle_Mountain,_Utah"
            },
            {
              "@type": "City",
              "name": "Saratoga Springs",
              "sameAs": "https://en.wikipedia.org/wiki/Saratoga_Springs,_Utah"
            },
            {
              "@type": "City",
              "name": "Farmington",
              "sameAs": "https://en.wikipedia.org/wiki/Farmington,_Utah"
            },
            {
              "@type": "City",
              "name": "Payson",
              "sameAs": "https://en.wikipedia.org/wiki/Payson,_Utah"
            },
            {
              "@type": "City",
              "name": "North Salt Lake",
              "sameAs": "https://en.wikipedia.org/wiki/North_Salt_Lake,_Utah"
            },
            {
              "@type": "City",
              "name": "North Ogden",
              "sameAs": "https://en.wikipedia.org/wiki/North_Ogden,_Utah"
            },
            {
              "@type": "City",
              "name": "Clinton",
              "sameAs": "https://en.wikipedia.org/wiki/Clinton,_Utah"
            },
            {
              "@type": "City",
              "name": "Highland",
              "sameAs": "https://en.wikipedia.org/wiki/Highland,_Utah"
            },
            {
              "@type": "City",
              "name": "South Ogden",
              "sameAs": "https://en.wikipedia.org/wiki/South_Ogden,_Utah"
            },
            {
              "@type": "City",
              "name": "Heber",
              "sameAs": "https://en.wikipedia.org/wiki/Heber,_Utah"
            },
            {
              "@type": "City",
              "name": "Bluffdale",
              "sameAs": "https://en.wikipedia.org/wiki/Bluffdale,_Utah"
            },
            {
              "@type": "City",
              "name": "Woodscross",
              "sameAs": "https://en.wikipedia.org/wiki/Woodscross,_Utah"
            },
            {
              "@type": "City",
              "name": "Lindon",
              "sameAs": "https://en.wikipedia.org/wiki/Lindon,_Utah"
            },
            {
              "@type": "City",
              "name": "Cedar Hills",
              "sameAs": "https://en.wikipedia.org/wiki/Cedar_Hills,_Utah"
            },
            {
              "@type": "City",
              "name": "Slpine",
              "sameAs": "https://en.wikipedia.org/wiki/Slpine,_Utah"
            },
            {
              "@type": "City",
              "name": "Park City",
              "sameAs": "https://en.wikipedia.org/wiki/Park_City,_Utah"
            },
            {
              "@type": "City",
              "name": "Fairfield",
              "sameAs": "https://en.wikipedia.org/wiki/Fairfield,_Utah"
            },
            {
              "@type": "City",
              "name": "Stockton",
              "sameAs": "https://en.wikipedia.org/wiki/Stockton,_Utah"
            },
            {
              "@type": "City",
              "name": "Kamas",
              "sameAs": "https://en.wikipedia.org/wiki/Kamas,_Utah"
            },
            {
              "@type": "City",
              "name": "Tremonton",
              "sameAs": "https://en.wikipedia.org/wiki/Tremonton,_Utah"
            },
            {
              "@type": "City",
              "name": "Logan",
              "sameAs": "https://en.wikipedia.org/wiki/Logan,_Utah"
            }
          ]
        }
      `}</script>
      </Helmet>
      <div className="max-w-2xl m-auto flex flex-wrap pt-8 pb-16 px-4 text-center text-grey-darker">
        <h1
          className="m-auto"
          dangerouslySetInnerHTML={{
            __html: props.currentPage.pages.h1Title
              ? props.currentPage.pages.h1Title
              : props.currentPage.title,
          }}
        />
      </div>
      <div className="max-w-2xl m-auto flex flex-wrap pb-16">
        <div className="w-full lg:w-1/2">
          <iframe
            src="https://www.google.com/maps/d/u/0/embed?mid=1X5W7-Wgzao5xCTNHwN0OZsZfRhwll3Y&ll=40.4,-111.9&z=8"
            width="100%"
            height="600"
            title="Service Area"
          ></iframe>
          <div className="leading-loose text-grey-darker px-4">
            <p className="p-4 lg:p-0">We deliver to the highlighted areas.</p>
            <ul className="text-sm">
              <li>
                ${SHIPPING_PRICES[process.env.COMPANY_NAME_SHORT][0]} for{" "}
                {zipCodes[0].label} Delivery Area
              </li>
              <li>
                ${SHIPPING_PRICES[process.env.COMPANY_NAME_SHORT][1]} for{" "}
                {zipCodes[1].label} Delivery Area
              </li>
              <li>
                ${SHIPPING_PRICES[process.env.COMPANY_NAME_SHORT][2]} for{" "}
                {zipCodes[2].label} Delivery Area
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full lg:w-1/2 p-4 lg:px-8 lg:py-0 mb-8 text-grey-darker max-w-2xl wp-content">
          <Formik
            innerRef={formikRef}
            initialValues={{
              shippingZip: cartContext.checkoutInfo.shippingZip
                ? cartContext.checkoutInfo.shippingZip
                : "",
            }}
            validateOnMount
            validate={(values) => {
              let errors = {};

              if (!values.shippingZip) {
                errors.shippingZip = "Required";
              } else if (values.shippingZip.length !== 5) {
                errors.shippingZip = "Please enter a valid zip code";
              } else if (
                allDeliverableZipCodes.indexOf(parseInt(values.shippingZip)) < 0
              ) {
                errors.shippingZip = `We do not currently deliver to that area. You can still order and pick up your order from our ${process.env.ADDRESS_CITY} location. Please call us for more information.`;
              }

              cartContext.setCheckoutInfo({
                ...cartContext.checkoutInfo,
                ...values,
              });

              return errors;
            }}
            onSubmit={(values) => {
              console.log(values);
            }}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="mb-12 pb-6 border-b border-grey-lighter">
                <div className="mb-6">
                  <label htmlFor="shippingZip" className="block mb-2 text-2xl">
                    <strong>Are you in our service area?</strong>
                  </label>
                  <Field
                    type="text"
                    name="shippingZip"
                    placeholder="Enter your zip code"
                    className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                  />
                  <p
                    className={`${
                      !errors.shippingZip && values.shippingZip.length === 5
                        ? `mt-2 text-${process.env.THEME_COLOR_PRIMARY}-darker`
                        : "hidden "
                    }`}
                  >
                    {shippingPrice(cartContext.checkoutInfo.shippingZip)}
                  </p>
                  <p
                    className={`${
                      values.shippingZip.length === 5 && errors.shippingZip
                        ? "mt-2 text-orange-dark"
                        : "hidden"
                    }`}
                  >
                    We do not currently deliver to that area. You can still
                    order and pick up your order from our{" "}
                    {process.env.ADDRESS_CITY} location. Please{" "}
                    <Link to="/contact-us/" className="text-orange-dark">
                      call us
                    </Link>{" "}
                    for more information.
                  </p>
                </div>
              </Form>
            )}
          </Formik>
          <div
            dangerouslySetInnerHTML={{
              __html: props.currentPage.content,
            }}
            className="wp-content"
          />
        </div>
      </div>
    </Layout>
  );
}
export default ServiceAreasPageTemplate;
