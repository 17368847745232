import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import AccessoriesPageTemplate from "./page-templates/accessories";
import ContactUsPageTemplate from "./page-templates/contact-us";
import AboutPageTemplate from "./page-templates/about";
import BlogPageTemplate from "./page-templates/blog";
import ServiceAreasPageTemplate from "./page-templates/service-areas";
import ProductComparisonPageTemplate from "./page-templates/product-comparison-page";

class PageTemplate extends Component {
  render() {
    const currentPage = this.props.data.wpPage;

    if (currentPage.slug === "window-well-accessories") {
      return <AccessoriesPageTemplate currentPage={currentPage} />;
    }

    if (currentPage.slug === "contact-us") {
      return <ContactUsPageTemplate currentPage={currentPage} />;
    }

    if (currentPage.slug === "about") {
      return <AboutPageTemplate currentPage={currentPage} />;
    }

    if (currentPage.slug === "blog") {
      return <BlogPageTemplate currentPage={currentPage} />;
    }

    if (currentPage.slug === "service-areas") {
      return <ServiceAreasPageTemplate currentPage={currentPage} />;
    }

    if (currentPage.slug === "compare-window-well-covers") {
      return <ProductComparisonPageTemplate currentPage={currentPage} />;
    }

    return (
      <Layout>
        <Seo
          title={currentPage.seo.title}
          description={currentPage.seo.metaDesc}
        />
        <div className="flex flex-wrap default-page">
          <div className="p-4 md:p-0 w-full md:w-1/2 m-auto my-8 text-grey-darker">
            <h1
              dangerouslySetInnerHTML={{
                __html: currentPage.pages.h1Title
                  ? currentPage.pages.h1Title
                  : currentPage.title,
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
          </div>
        </div>
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      slug
      status
      content
      template {
        templateName
      }
      pages {
        h1Title
      }
      layout {
        layoutType
        sections {
          photo {
            slug
            altText
            title
            localFile {
              publicURL
            }
          }
          emphasizedProduct
          photoPosition
          sectionTitle
          sectionTitleLink
          sectionBody
          price
          buttonText
          buttonLink
          postButtonText
          featureItems {
            warranty
            weightSupport
            extraInfo
          }
        }
        homePage {
          subtitle
          bigButtons {
            leftText
            rightText
            link
          }
          section1Body
          section2Body
        }
      }
      seo {
        title
        metaDesc
        canonical
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`;
