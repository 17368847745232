import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { navigate } from "gatsby";
import React from "react";
import PhoneInputField from "../../components/formik/PhoneInputField";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

function ContactUsPageTemplate(props) {
  return (
    <Layout>
      <Seo
        title={props.currentPage.seo.title}
        description={props.currentPage.seo.metaDesc}
      />
      <div className="flex flex-wrap items-start default-page max-w-2xl m-auto my-8">
        <div className="p-4 pr-8 w-full md:w-1/2 text-grey-darker wp-content">
          <h1
            dangerouslySetInnerHTML={{
              __html: props.currentPage.pages.h1Title
                ? props.currentPage.pages.h1Title
                : props.currentPage.title,
            }}
          />
          <div
            dangerouslySetInnerHTML={{ __html: props.currentPage.content }}
          />
        </div>
        <div className="w-full md:w-1/2 p-4 md:p-0">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              preferredMethodOfContact: "",
              email: "",
              phone: "",
              bestTimeToContact: "",
              howManyWells: "",
              message: "",
            }}
            validate={(values) => {
              let errors = {};

              if (!values.preferredMethodOfContact) {
                errors.preferredMethodOfContact = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (
                !values.preferredMethodOfContact ||
                values.preferredMethodOfContact === "phone"
              ) {
                if (!values.phone) {
                  errors.phone = "Required";
                }
              }
              return errors;
            }}
            onSubmit={(values, actions) => {
              axios
                .post(
                  `${process.env.DATA_API}/?contact_form_submission`,
                  values
                )
                .then((contactResponse) => {
                  actions.setSubmitting(false);
                  if (contactResponse.data.success) {
                    navigate("/contact-success/");
                  } else {
                    alert(contactResponse.data.data.error_text);
                  }
                });
            }}
            enableReinitialize={true}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form className="mt-8">
                <div className={isSubmitting ? "submitting-payment" : "hidden"}>
                  Securely submitting form...
                </div>
                <div className="flex flex-wrap w-full items-stretch">
                  <div className="mb-6 w-1/2 px-2">
                    <label htmlFor="firstName" className="block mb-2">
                      <strong>First Name</strong>{" "}
                    </label>
                    <Field
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="firstName"
                      component="div"
                    />
                  </div>
                  <div className="mb-6 w-1/2 px-2">
                    <label htmlFor="lastName" className="block mb-2">
                      <strong>Last Name</strong>{" "}
                    </label>
                    <Field
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="lastName"
                      component="div"
                    />
                  </div>
                </div>

                <div className="flex flex-wrap w-full items-stretch">
                  <div className="mb-6 w-1/2 px-2">
                    <label
                      htmlFor="preferredMethodOfContact"
                      className="block mb-2"
                    >
                      <strong>Preferred Method of Contact</strong>{" "}
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <Field
                      component="select"
                      name="preferredMethodOfContact"
                      className="bg-white appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    >
                      <option value="" />
                      <option value="email">Email</option>
                      <option value="phone">Phone</option>
                    </Field>
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="preferredMethodOfContact"
                      component="div"
                    />
                  </div>

                  <div className="mb-6 w-1/2 px-2">
                    <label htmlFor="email" className="block mb-2">
                      <strong>Email</strong>{" "}
                      <abbr className="required" title="Required">
                        *
                      </abbr>
                    </label>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="email"
                      component="div"
                    />
                  </div>

                  <div className={`mb-6 w-1/2 px-2`}>
                    <label htmlFor="phone" className="block mb-2">
                      <strong>Phone Number</strong>{" "}
                      <abbr
                        className={`${
                          !values.preferredMethodOfContact ||
                          values.preferredMethodOfContact === "email"
                            ? "hidden "
                            : ""
                        }required`}
                        title="Required"
                      >
                        *
                      </abbr>
                    </label>
                    <Field
                      autocomplete="tel-national"
                      name="phone"
                      component={PhoneInputField}
                      id="phone"
                      country="United States"
                      placeholder={`(${process.env.DEFAULT_AREA_CODE}) 111-1111`}
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                      type="tel"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="phone"
                      component="div"
                    />
                  </div>

                  <div className="mb-6 w-1/2 px-2">
                    <label htmlFor="bestTimeToContact" className="block mb-2">
                      <strong>Best time to reach you</strong>{" "}
                    </label>
                    <Field
                      component="select"
                      name="bestTimeToContact"
                      className="bg-white appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-10"
                    >
                      <option value="" />
                      <option value="morning">Morning</option>
                      <option value="afternoon">Afternoon</option>
                      <option value="evening">Evening</option>
                    </Field>
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="bestTimeToContact"
                      component="div"
                    />
                  </div>

                  <div className="mb-6 w-1/2 px-2">
                    <label htmlFor="howManyWells" className="block mb-2">
                      <strong>How many wells do you have?</strong>{" "}
                    </label>
                    <Field
                      type="number"
                      min="0"
                      name="howManyWells"
                      id="howManyWells"
                      className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring"
                    />
                    <ErrorMessage
                      className="text-red-dark text-sm mt-2"
                      name="howManyWells"
                      component="div"
                    />
                  </div>
                </div>

                <div className="mb-6 px-2">
                  <label htmlFor="message" className="block mb-2">
                    <strong>Message</strong>{" "}
                  </label>
                  <Field
                    component="textarea"
                    type="textarea"
                    name="message"
                    className="appearance-none border border-grey-light rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:ring h-32"
                  />
                  <ErrorMessage
                    className="text-red-dark text-sm mt-2"
                    name="message"
                    component="div"
                  />
                </div>

                <div className="flex mb-6 px-2 justify-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`block md:inline-block bg-${process.env.THEME_COLOR_PRIMARY} text-white text-sm px-4 py-3 no-underline rounded uppercase`}
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
}
export default ContactUsPageTemplate;
